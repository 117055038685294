$font-size-xs: ($font-size-base * .75) !default;
$line-height-xs: 1.5 !default;

$input-btn-padding-y-xs: .125rem !default;
$input-btn-padding-x-xs: .25rem !default;
$input-btn-font-size-xs: $font-size-xs !default;
$input-btn-line-height-xs: $line-height-xs !default;

$btn-padding-y-xs: $input-btn-padding-y-xs !default;
$btn-padding-x-xs: $input-btn-padding-x-xs !default;
$btn-font-size-xs: $input-btn-font-size-xs !default;
$btn-line-height-xs: $input-btn-line-height-xs !default;

$border-radius-xs: .1rem !default;
$btn-border-radius-xs: $border-radius-xs !default;

@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
}


.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);

  &.btn-icon {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
  }
}

.m-t-10 {
  margin-top: 10px
}

.m-l-10 {
  margin-left: 10px
}

.m-r-10 {
  margin-right: 10px
}

.m-b-10 {
  margin-bottom: 10px
}

.m-10 {
  margin: 10px
}

.p-10 {
  padding: 10px
}

.p-t-10 {
  padding-top: 10px
}

.p-b-10 {
  padding-bottom: 10px
}

.p-l-10 {
  padding-left: 10px
}

.p-r-10 {
  padding-right: 10px
}

.m-t-20 {
  margin-top: 20px
}

.m-l-20 {
  margin-left: 20px
}

.m-r-20 {
  margin-right: 20px
}

.m-b-20 {
  margin-bottom: 20px
}

.m-20 {
  margin: 20px
}

.p-20 {
  padding: 20px
}

.p-t-20 {
  padding-top: 20px
}

.p-b-20 {
  padding-bottom: 20px
}

.p-l-20 {
  padding-left: 20px
}

.p-r-20 {
  padding-right: 20px
}

.m-t-30 {
  margin-top: 30px
}

.m-l-30 {
  margin-left: 30px
}

.m-r-30 {
  margin-right: 30px
}

.m-b-30 {
  margin-bottom: 30px
}

.m-30 {
  margin: 30px
}

.p-30 {
  padding: 30px
}

.p-t-30 {
  padding-top: 30px
}

.p-b-30 {
  padding-bottom: 30px
}

.p-l-30 {
  padding-left: 30px
}

.p-r-30 {
  padding-right: 30px
}

.m-t-40 {
  margin-top: 40px
}

.m-l-40 {
  margin-left: 40px
}

.m-r-40 {
  margin-right: 40px
}

.m-b-40 {
  margin-bottom: 40px
}

.m-40 {
  margin: 40px
}

.p-40 {
  padding: 40px
}

.p-t-40 {
  padding-top: 40px
}

.p-b-40 {
  padding-bottom: 40px
}

.p-l-40 {
  padding-left: 40px
}

.p-r-40 {
  padding-right: 40px
}

.m-t-50 {
  margin-top: 50px
}

.m-l-50 {
  margin-left: 50px
}

.m-r-50 {
  margin-right: 50px
}

.m-b-50 {
  margin-bottom: 50px
}

.m-50 {
  margin: 50px
}

.p-50 {
  padding: 50px
}

.p-t-50 {
  padding-top: 50px
}

.p-b-50 {
  padding-bottom: 50px
}

.p-l-50 {
  padding-left: 50px
}

.p-r-50 {
  padding-right: 50px
}

.m-t-60 {
  margin-top: 60px
}

.m-l-60 {
  margin-left: 60px
}

.m-r-60 {
  margin-right: 60px
}

.m-b-60 {
  margin-bottom: 60px
}

.m-60 {
  margin: 60px
}

.p-60 {
  padding: 60px
}

.p-t-60 {
  padding-top: 60px
}

.p-b-60 {
  padding-bottom: 60px
}

.p-l-60 {
  padding-left: 60px
}

.p-r-60 {
  padding-right: 60px
}

.m-t-70 {
  margin-top: 70px
}

.m-l-70 {
  margin-left: 70px
}

.m-r-70 {
  margin-right: 70px
}

.m-b-70 {
  margin-bottom: 70px
}

.m-70 {
  margin: 70px
}

.p-70 {
  padding: 70px
}

.p-t-70 {
  padding-top: 70px
}

.p-b-70 {
  padding-bottom: 70px
}

.p-l-70 {
  padding-left: 70px
}

.p-r-70 {
  padding-right: 70px
}

.p-0-force, table.dataTable.quote-prices-table tbody td {
  padding: 0 !important
}

.m-t-0-force {
  margin-top: 0 !important
}

.m-l-0-force {
  margin-left: 0 !important
}

.m-r-0-force {
  margin-right: 0 !important
}

.m-b-0-force {
  margin-bottom: 0 !important
}

.m-0-force {
  margin: 0 !important
}

.p-t-0-force {
  padding-top: 0 !important
}

.p-b-0-force {
  padding-bottom: 0 !important
}

.p-l-0-force {
  padding-left: 0 !important
}

.p-r-0-force {
  padding-right: 0 !important
}

.m-t-10-force {
  margin-top: 10px !important
}

.m-l-10-force {
  margin-left: 10px !important
}

.m-r-10-force {
  margin-right: 10px !important
}

.m-b-10-force {
  margin-bottom: 10px !important
}

.m-10-force {
  margin: 10px !important
}

.p-10-force {
  padding: 10px !important
}

.p-t-10-force {
  padding-top: 10px !important
}

.p-b-10-force {
  padding-bottom: 10px !important
}

.p-l-10-force {
  padding-left: 10px !important
}

.p-r-10-force {
  padding-right: 10px !important
}

.m-t-20-force {
  margin-top: 20px !important
}

.m-l-20-force {
  margin-left: 20px !important
}

.m-r-20-force {
  margin-right: 20px !important
}

.m-b-20-force {
  margin-bottom: 20px !important
}

.m-20-force {
  margin: 20px !important
}

.p-20-force {
  padding: 20px !important
}

.p-t-20-force {
  padding-top: 20px !important
}

.p-b-20-force {
  padding-bottom: 20px !important
}

.p-l-20-force {
  padding-left: 20px !important
}

.p-r-20-force {
  padding-right: 20px !important
}

.m-t-30-force {
  margin-top: 30px !important
}

.m-l-30-force {
  margin-left: 30px !important
}

.m-r-30-force {
  margin-right: 30px !important
}

.m-b-30-force {
  margin-bottom: 30px !important
}

.m-30-force {
  margin: 30px !important
}

.p-30-force {
  padding: 30px !important
}

.p-t-30-force {
  padding-top: 30px !important
}

.p-b-30-force {
  padding-bottom: 30px !important
}

.p-l-30-force {
  padding-left: 30px !important
}

.p-r-30-force {
  padding-right: 30px !important
}

.m-t-40-force {
  margin-top: 40px !important
}

.m-l-40-force {
  margin-left: 40px !important
}

.m-r-40-force {
  margin-right: 40px !important
}

.m-b-40-force {
  margin-bottom: 40px !important
}

.m-40-force {
  margin: 40px !important
}

.p-40-force {
  padding: 40px !important
}

.p-t-40-force {
  padding-top: 40px !important
}

.p-b-40-force {
  padding-bottom: 40px !important
}

.p-l-40-force {
  padding-left: 40px !important
}

.p-r-40-force {
  padding-right: 40px !important
}

.m-t-50-force {
  margin-top: 50px !important
}

.m-l-50-force {
  margin-left: 50px !important
}

.m-r-50-force {
  margin-right: 50px !important
}

.m-b-50-force {
  margin-bottom: 50px !important
}

.m-50-force {
  margin: 50px !important
}

.p-50-force {
  padding: 50px !important
}

.p-t-50-force {
  padding-top: 50px !important
}

.p-b-50-force {
  padding-bottom: 50px !important
}

.p-l-50-force {
  padding-left: 50px !important
}

.p-r-50-force {
  padding-right: 50px !important
}

.m-t-60-force {
  margin-top: 60px !important
}

.m-l-60-force {
  margin-left: 60px !important
}

.m-r-60-force {
  margin-right: 60px !important
}

.m-b-60-force {
  margin-bottom: 60px !important
}

.m-60-force {
  margin: 60px !important
}

.p-60-force {
  padding: 60px !important
}

.p-t-60-force {
  padding-top: 60px !important
}

.p-b-60-force {
  padding-bottom: 60px !important
}

.p-l-60-force {
  padding-left: 60px !important
}

.p-r-60-force {
  padding-right: 60px !important
}

.m-t-70-force {
  margin-top: 70px !important
}

.m-l-70-force {
  margin-left: 70px !important
}

.m-r-70-force {
  margin-right: 70px !important
}

.m-b-70-force {
  margin-bottom: 70px !important
}

.m-70-force {
  margin: 70px !important
}

.p-70-force {
  padding: 70px !important
}

.p-t-70-force {
  padding-top: 70px !important
}

.p-b-70-force {
  padding-bottom: 70px !important
}

.p-l-70-force {
  padding-left: 70px !important
}

.p-r-70-force {
  padding-right: 70px !important
}

.m-t-0 {
  margin-top: 0
}

.m-l-0 {
  margin-left: 0
}

.m-r-0 {
  margin-right: 0
}

.m-b-0 {
  margin-bottom: 0
}

.mar-0 {
  margin: 0
}

.pad-0 {
  padding: 0
}

.p-t-0 {
  padding-top: 0
}

.p-b-0 {
  padding-bottom: 0
}

.p-l-0 {
  padding-left: 0
}

.p-r-0 {
  padding-right: 0
}

.m-t-1 {
  margin-top: 1px
}

.m-l-1 {
  margin-left: 1px
}

.m-r-1 {
  margin-right: 1px
}

.m-b-1 {
  margin-bottom: 1px
}

.mar-1 {
  margin: 1px
}

.pad-1 {
  padding: 1px
}

.p-t-1 {
  padding-top: 1px
}

.p-b-1 {
  padding-bottom: 1px
}

.p-l-1 {
  padding-left: 1px
}

.p-r-1 {
  padding-right: 1px
}

.m-t-2 {
  margin-top: 2px
}

.m-l-2 {
  margin-left: 2px
}

.m-r-2 {
  margin-right: 2px
}

.m-b-2 {
  margin-bottom: 2px
}

.mar-2 {
  margin: 2px
}

.pad-2 {
  padding: 2px
}

.p-t-2 {
  padding-top: 2px
}

.p-b-2 {
  padding-bottom: 2px
}

.p-l-2 {
  padding-left: 2px
}

.p-r-2 {
  padding-right: 2px
}

.pad-3, body .dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 3px
}

.m-t-3 {
  margin-top: 3px
}

.m-l-3 {
  margin-left: 3px
}

.m-r-3 {
  margin-right: 3px
}

.m-b-3 {
  margin-bottom: 3px
}

.mar-3 {
  margin: 3px
}

.p-t-3 {
  padding-top: 3px
}

.p-b-3 {
  padding-bottom: 3px
}

.p-l-3 {
  padding-left: 3px
}

.p-r-3 {
  padding-right: 3px
}

.m-t-4 {
  margin-top: 4px
}

.m-l-4 {
  margin-left: 4px
}

.m-r-4 {
  margin-right: 4px
}

.m-b-4 {
  margin-bottom: 4px
}

.mar-4 {
  margin: 4px
}

.pad-4 {
  padding: 4px
}

.p-t-4 {
  padding-top: 4px
}

.p-b-4 {
  padding-bottom: 4px
}

.p-l-4 {
  padding-left: 4px
}

.p-r-4 {
  padding-right: 4px
}

.m-t-5 {
  margin-top: 5px
}

.m-l-5 {
  margin-left: 5px
}

.m-r-5 {
  margin-right: 5px
}

.m-b-5 {
  margin-bottom: 5px
}

.mar-5 {
  margin: 5px
}

.pad-5 {
  padding: 5px
}

.p-t-5 {
  padding-top: 5px
}

.p-b-5 {
  padding-bottom: 5px
}

.p-l-5 {
  padding-left: 5px
}

.p-r-5 {
  padding-right: 5px
}

.b-100 {
  font-weight: 100
}

.b-200 {
  font-weight: 200
}

.b-300 {
  font-weight: 300
}

.b-400 {
  font-weight: 400
}

.b-500 {
  font-weight: 500
}

.b-600 {
  font-weight: 600
}

.b-700 {
  font-weight: 700
}

.b-800 {
  font-weight: 800
}

.b-900 {
  font-weight: 900
}

.o-0 {
  opacity: 0
}

.o-1 {
  opacity: .1
}

.o-2 {
  opacity: .2
}

.o-3 {
  opacity: .3
}

.o-4 {
  opacity: .4
}

.o-5 {
  opacity: .5
}

.o-6 {
  opacity: .6
}

.o-7 {
  opacity: .7
}

.o-8 {
  opacity: .8
}

.o-9 {
  opacity: .9
}

.z-1 {
  z-index: 1
}

#main-navbar > li, .z-2 {
  z-index: 2
}

.z-3 {
  z-index: 3
}

.z-4 {
  z-index: 4
}

.z-5 {
  z-index: 5
}

.flex-order-1 {
  order: 1
}

.flex-order--1 {
  order: 99
}

@media (max-width: 767px) {
  .xs-flex-order--1 {
    order: 99
  }
}

.flex-order-2 {
  order: 2
}

.flex-order--2 {
  order: 98
}

@media (max-width: 767px) {
  .xs-flex-order--2 {
    order: 98
  }
}

.flex-order-3 {
  order: 3
}

.flex-order--3 {
  order: 97
}

@media (max-width: 767px) {
  .xs-flex-order--3 {
    order: 97
  }
}

.flex-order-4 {
  order: 4
}

.flex-order--4 {
  order: 96
}

@media (max-width: 767px) {
  .xs-flex-order--4 {
    order: 96
  }
}

.flex-order-5 {
  order: 5
}

.flex-order--5 {
  order: 95
}

@media (max-width: 767px) {
  .xs-flex-order--5 {
    order: 95
  }
}

.flex-order-6 {
  order: 6
}

.flex-order--6 {
  order: 94
}

@media (max-width: 767px) {
  .xs-flex-order--6 {
    order: 94
  }
}

.flex-order-7 {
  order: 7
}

.flex-order--7 {
  order: 93
}

@media (max-width: 767px) {
  .xs-flex-order--7 {
    order: 93
  }
}

.flex-order-8 {
  order: 8
}

.flex-order--8 {
  order: 92
}

@media (max-width: 767px) {
  .xs-flex-order--8 {
    order: 92
  }
}

.flex-order-9 {
  order: 9
}

.flex-order--9 {
  order: 91
}

@media (max-width: 767px) {
  .xs-flex-order--9 {
    order: 91
  }
}

.flex-order-10 {
  order: 10
}

.flex-order--10 {
  order: 90
}

@media (max-width: 767px) {
  .xs-flex-order--10 {
    order: 90
  }
}

.flex-order-11 {
  order: 11
}

.flex-order--11 {
  order: 89
}

@media (max-width: 767px) {
  .xs-flex-order--11 {
    order: 89
  }
}

.flex-order-12 {
  order: 12
}

.flex-order--12 {
  order: 88
}

@media (max-width: 767px) {
  .xs-flex-order--12 {
    order: 88
  }
}

.flex-order-13 {
  order: 13
}

.flex-order--13 {
  order: 87
}

@media (max-width: 767px) {
  .xs-flex-order--13 {
    order: 87
  }
}

.flex-order-14 {
  order: 14
}

.flex-order--14 {
  order: 86
}

@media (max-width: 767px) {
  .xs-flex-order--14 {
    order: 86
  }
}

.flex-order-15 {
  order: 15
}

.flex-order--15 {
  order: 85
}

@media (max-width: 767px) {
  .xs-flex-order--15 {
    order: 85
  }
}

.flex-order-16 {
  order: 16
}

.flex-order--16 {
  order: 84
}

@media (max-width: 767px) {
  .xs-flex-order--16 {
    order: 84
  }
}

.flex-order-17 {
  order: 17
}

.flex-order--17 {
  order: 83
}

@media (max-width: 767px) {
  .xs-flex-order--17 {
    order: 83
  }
}

.flex-order-18 {
  order: 18
}

.flex-order--18 {
  order: 82
}

@media (max-width: 767px) {
  .xs-flex-order--18 {
    order: 82
  }
}

.flex-order-19 {
  order: 19
}

.flex-order--19 {
  order: 81
}

@media (max-width: 767px) {
  .xs-flex-order--19 {
    order: 81
  }
}

.flex-order-20 {
  order: 20
}

.flex-order--20 {
  order: 80
}

.m-t--10 {
  margin-top: -10px
}

.m-t--7 {
  margin-top: -7px
}

.m-t--5 {
  margin-top: -5px
}

.m-l--15 {
  margin-left: -15px
}

.m-r--5 {
  margin-right: -5px
}

.v-a-t {
  vertical-align: top
}

.table-vertical-middle table > tbody > tr > td, .table-vertical-middle table > tbody > tr > th, .table-vertical-middle table > tfoot > tr > td, .table-vertical-middle table > tfoot > tr > th, .table-vertical-middle table > thead > tr > td, .table-vertical-middle table > thead > tr > th, .v-a-m {
  vertical-align: middle
}

.v-a-b {
  vertical-align: bottom
}

body .p-r {
  position: relative
}

body .p-f {
  position: fixed
}

body .t-0 {
  top: 0
}

body .l-auto {
  left: auto
}

body .l-0 {
  left: 0
}

body .r-0 {
  right: 0
}

body .b-0 {
  bottom: 0
}

.p-t-6 {
  padding-top: 6px
}

.w100 {
  width: 100%
}

.h100 {
  height: 100%
}

.o-h {
  overflow: hidden
}

.max-text {
  max-width: 100%;
  overflow: hidden
}

.o-auto {
  max-width: 100%;
  overflow: auto
}

.mw100 {
  max-width: 100%
}

.mh100 {
  max-height: 100%
}

.m-l-5n4 {
  margin-left: .338rem
}


#main-body .hide {
  display: none;
}

.p-a {
  position: absolute;
}

.p-r {
  position: relative;
}

.p-f {
  position: fixed;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#notification-info {
  overflow: hidden;
  height: 30px;
}

#notification-bar {
  .minimax {
    position: relative;
    width: 16px;
    height: 20px;
    display: inline-block;
  }

  .minimize {
    position: absolute;
    left: 0;
    top: 0px;
    transition: .3s all ease-in-out;
    opacity: 1;
  }

  .maximize {
    position: absolute;
    left: 0;
    top: -20px;
    transition: .3s all ease-in-out;
    opacity: .1;
  }

  &.minified {
    #notification-contents {
      display: none;
    }

    .minimize {
      top: 20px;
      opacity: .1;
    }

    .maximize {
      top: 0;
      opacity: 1;
    }
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}


.multi-checkbox {
  .select2-results__option {
    > .wrap:before {
      font-family: "Font Awesome 5 Free";
      color: #999;
      content: "\f0c8";
      width: 25px;
      height: 25px;
      padding-right: 10px;
    }

    &[aria-selected=true] > .wrap:before {
      content: "\f14a";
    }

    &.select2-results__option--highlighted > .wrap:before {
      color: white;
    }
  }
}

.table-centered {
  table > thead > tr > th,
  table > thead > tr > td,
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    text-align: center;
  }
}

.table-left {
  table > thead > tr > th,
  table > thead > tr > td,
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    text-align: left;
  }
}

.table-vertical-middle {
  table > thead > tr > th,
  table > thead > tr > td,
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    vertical-align: middle;
  }
}

#main-body {
  .breadcrumb-item + .breadcrumb-item {
    &::before {
      content: "•";
      color: #646c9a;
    }

    & > a {
      padding: 0 0.35rem 0 0;
      font-size: 1rem;
      font-weight: 500;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }

  .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
  }

  [v-cloak] {
    display: none
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-move {
    cursor: move;
  }


  .text-danger-o {
    color: transparentize(theme-color('danger'), .3);
  }

  .text-info-o {
    color: transparentize(theme-color('info'), .3);
  }

  .text-success-o {
    color: transparentize(theme-color('success'), .3);
  }
}


.nowrap {
  white-space: nowrap;
}

.list-view {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
  max-height: 100%;
}

#main-body {

  .alert {
    flex: 0 0 auto;
  }
}

@media (min-width: #{kt-media-breakpoint(xl) + 1px}) {
  #main-body .btn.btn-icon-xl {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding-left: .5rem;
    padding-right: .5rem;

    &.btn-sm {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      padding-left: .5rem;
      padding-right: .5rem;

      i {
        margin-right: .5rem;
      }
    }

    &.btn-lg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      padding-left: .5rem;
      padding-right: .5rem;

      i {
        margin-right: .5rem;
      }
    }
  }
}