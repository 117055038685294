//
// Aside Secondary Config
//




$kt-aside-secondary-config: (
	self: (
		zindex: $kt-dropdown-zindex - 6,
		width: (
			default: 60px,
			expanded: 370px
		),
		bg-color: #ffffff,
		shadow: 0px 0px 28px 0px rgba(82,63,105,0.13)
	)
);