#main-body {

  .select2-container {
    width: -webkit-fit-content; /* Safari hack*/
    width: fit-content;
  }
  .select2-container--krajee .select2-selection {
    box-shadow: none;
  }

  .select2-container--krajee .select2-selection--single {
    @extend .form-control;
    padding-right: 33px;

    .select2-selection__rendered {
      margin-right: -5px;
      padding-right: 5px;

      .select2-selection__clear {
        top: -1px;
        right: -5px;
      }
    }

    .select2-selection__arrow {
      height: 36px;
      border-left-color: #e2e5ec;
    }
  }

  form .form-control,
  select.form-control,
  .select2-selection--single {
    padding-left: .5rem;
  }

  $invalid_color: kt-base-color(label, 3);
  $valid_color: kt-brand-color();

  .form-control.is-invalid + .select2-container--krajee .select2-selection {
    @extend .form-control.is-invalid;
  }

  .form-control.is-invalid:focus + .select2-container--krajee .select2-selection {
    @extend .form-control.is-invalid;
    border-color: $invalid_color;
  }

  .form-control.is-valid + .select2-container--krajee .select2-selection {
    @extend .form-control.is-valid;
  }

  .form-control.is-valid:focus + .select2-container--krajee .select2-selection {
    border-color: $valid_color;
  }

  .form-control.is-valid:focus {
    border-color: $valid_color;
  }

  .form-control.is-invalid:focus {
    border-color: $invalid_color;
  }

  input[type=radio]:focus + span,
  input[type=checkbox]:focus + span {
    border-color: $valid_color;
  }

  input.is-invalid:focus + span {
    border-color: $invalid_color;
  }

  input.is-valid:focus + span {
    border-color: $valid_color;
  }

  select + .select2.select2-container--bootstrap {
    width: 100% !important;

    &.select2-container--disabled {
      .select2-selection {
        background-color: rgb(247, 248, 250);
      }
    }
  }

  .select2-container--bootstrap .select2-selection {
    box-shadow: none;
  }

  .select2-container--bootstrap .select2-selection--single {
    @extend .form-control;
    padding-right: 33px;

    .select2-selection__rendered {
      margin-right: -5px;
      padding-right: 5px;
      //padding-left: 8px;
      pointer-events: none;


      .select2-selection__clear {
        top: -1px;
        right: -5px;
        pointer-events: all;
      }

      &[title=""] {
        .select2-selection__clear {
          display: none;
        }
      }

      & > div {
        max-width: 100%;
        overflow: hidden;
        text-overflow: fade;
        pointer-events: painted;
      }
    }

    .select2-selection__arrow {
      height: 36px;
      border-left-color: #e2e5ec;
    }
  }

  .form-control.is-invalid + .select2-container--bootstrap .select2-selection {
    @extend .form-control.is-invalid;
    background-image: none;
  }


  .form-group.required .form-control.is-valid + .select2-container--bootstrap .select2-selection {
    @extend .form-control.is-valid;
    background-image: none;
  }

  .form-group .form-control {
    background-image: none;
  }

  .form-group:not(.required) .form-control.is-valid {
    display: block;
    width: 100%;
    padding: $input-btn-padding-y $input-btn-padding-x;
    font-size: $font-size-base;
    line-height: $input-btn-line-height;
    color: $input-color;
    background-color: $input-bg;
    // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
    background-image: none;
    background-clip: padding-box;
    border: $input-btn-border-width solid $input-border-color;

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @if $enable-rounded {
      // Manually use the if/else instead of the mixin to account for iOS override
      border-radius: $input-border-radius;
    } @else {
      // Otherwise undo the iOS default
      border-radius: 0;
    }

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus();

    // Placeholder
    &::placeholder {
      color: $input-placeholder-color;
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }

  }

  .kv-plugin-loading {
    display: none !important;

    & + select,
    & + input + select {
      display: block !important;
      height: calc(1.5em + 1.3rem + 2px);
    }

    & + select[multiple],
    & + input + select[multiple] {
      overflow: hidden !important;

      & > option {
        display: none !important;
        &:first-child {
          display: inherit!important;
        }
      }

      & > option[selected]:first-of-type {
        background-color: transparent !important;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        display: block !important;
        margin-top: 2px;
        margin-left: -2px;
        box-shadow: inset 0 0 20px 20px white;
      }
    }
  }
}
