//
// Wizard v1
//




$kt-wizard-v1-step-bg: #f6f6f9;
$kt-wizard-v1-step-number: #9896a6;
$kt-wizard-v1-font: #918ea7;

// General mode
.kt-wizard-v1 {
	// Nav
	.kt-wizard-v1__nav {
		padding: 4rem 2rem 3.5rem;

		@include kt-minimal-desktop-and-below{
			padding: 2rem 1rem 3.5rem;
		}

		.kt-wizard-v1__nav-items{
			display:flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 4rem;

			@include kt-mobile{
				flex-wrap: wrap;
			}

			.kt-wizard-v1__nav-item{
				width: 3.5rem;
				height: 3.5rem;
				font-size: 1.5rem;
				border-radius: 50%;
				background-color: $kt-wizard-v1-step-bg;
				color: $kt-wizard-v1-step-number;
				display:flex;
				align-items: center;
				justify-content: center;
				margin: 0 1rem 1rem;
				font-weight: 500;

				&[data-ktwizard-state="current"]{
					background-color: kt-state-color(brand, base);
					color: kt-state-color(brand, inverse)
				}
			}
		}

		.kt-wizard-v1__nav-details {
			text-align:center;

			.kt-wizard-v1__nav-item-title{
				font-size: 1.5rem;
				font-weight: 500;
				margin-bottom: 2rem;
			}

			.kt-wizard-v1__nav-item-desc{
				font-weight:500;
				font-size: 1.1rem;
				line-height: 1.8rem;
				color: $kt-wizard-v1-font;

				.kt-wizard-v1__nav-image{
					display:block;
					margin: 5rem auto 0;
					max-height: 25rem;
					max-width:100%;
				}
			}
		}
	} 

	.kt-form {
		padding: 2rem 4rem 4rem;

		@include kt-mobile-sm{
			padding: 2rem 1rem 4rem;
		}

		.kt-wizard-v1__content{
			padding-bottom: 1.3rem;
			border-bottom: 1px solid #eeeef4;
			margin-bottom: 2rem;
		}

		.kt-form__actions{
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			@include kt-mobile-sm{
				flex-wrap: wrap;
				justify-content: center;
			}

			.btn{
				@include kt-mobile-sm{
					margin: 0 0.5rem 1rem;
				}
			}

			[data-ktwizard-type="action-prev"]{
				margin-right:auto;

				@include kt-mobile-sm{
					margin-right:0.5rem;
				}
			}

			[data-ktwizard-type="action-next"]{
				margin: auto 0 auto auto;

				@include kt-mobile-sm{
					margin: 0 0 1rem;
				}
			}
		}
	}	

	// Step
	[data-ktwizard-type="step"] {
		
		&[data-ktwizard-state="current"] {
			
		}

		&[data-ktwizard-state="done"] {
			
		}

		&[data-ktwizard-state="pending"] {
			
		}
	}

	// Step info
	[data-ktwizard-type="step-info"] {
		display: none;

		&[data-ktwizard-state="current"] {
			display: block;
		}
	}

	// Content states
	[data-ktwizard-type="step-content"] {
		display: none;
		
		&[data-ktwizard-state="current"] {
			display: block;
		}
	}


 	// Action buttons states
	& {
		[data-ktwizard-type="action-prev"]{
			display: none;
		}

		[data-ktwizard-type="action-next"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-submit"]{
			display: none;
		}
	}
 	
	&[data-ktwizard-state="first"] {
		[data-ktwizard-type="action-prev"]{
			display: none;
		}

		[data-ktwizard-type="action-next"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-submit"]{
			display: none;
		}
	}

	&[data-ktwizard-state="between"] {
		[data-ktwizard-type="action-prev"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-next"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-submit"]{
			display: none;
		}
	}

	&[data-ktwizard-state="last"] {
		[data-ktwizard-type="action-prev"]{
			display: inline-block;
		}

		[data-ktwizard-type="action-next"]{
			display: none;
		}

		[data-ktwizard-type="action-submit"]{
			display: inline-block;
		}
	}
}

// Responsive mode(ref $kt-media-breakpoints in framework\_config.scss)
// Above 1200px
@include kt-media-above(xl) {
	.kt-wizard-v1 {
		.kt-wizard-v1__aside {
			width: 400px;
			border-right: 1px solid #eeeef4;
			padding: 1.5rem;
		}

		.kt-wizard-v1__wrapper {
			padding: 1.5rem;
		}

		&.kt-wizard-v1--extend{
			.kt-wizard-v1__aside {
				width: 50%;
			}
		}
	}
}

// Above 1024px and below 1200px
@include kt-media-range(lg, xl) {
	.kt-wizard-v1 {
		.kt-wizard-v1__aside {
			border-bottom: 1px solid #eeeef4;
			padding: 1rem;
		}

		.kt-wizard-v1__wrapper {
			padding: 1rem;
		}
	}
}

// Below 1024px
@include kt-media-below(lg) {
	.kt-wizard-v1 {
		.kt-wizard-v1__aside {
			padding: 1rem;
			border-bottom: 1px solid #eeeef4;
		}

		.kt-wizard-v1__wrapper {
			padding: 1rem;
		}
	}
}