.collapse.vertical {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;

  &.right:not(.show) {
    display: block;
    transform: none;
    margin-right: -242px;
    width: 250px;
    height: auto;

    &.iconic {
      margin-right: 0;
      width: 70px;
    }

    i {
      padding: 0;
      margin: 0;
      line-height: 0;
    }

    span {
      display: none;
    }

    ul li button {
      overflow: hidden;
      max-width: 100%;
      //height: 35px;
      //display: -webkit-inline-box;
      //display: -ms-inline-flexbox;
      //display: inline-flex;
      //-webkit-box-align: center;
      //-ms-flex-align: center;
      //align-items: center;
      //-webkit-box-pack: center;
      //-ms-flex-pack: center;
      //justify-content: center;
      //padding: 0;

    }

    &.iconic {
      ul li button {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        padding-left: 4px;

        span {
          display: inline-block;
          opacity: 0;
        }
      }
    }

    .text {
      display: none;
    }

  }

  &.right {
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    width: 250px;
    height: 100% !important;
    overflow: visible;
    padding-left: 14px;
    padding-right: 14px;

    ul li button i {
      width: 26px;
      display: inline-block;
      text-align: center;
    }

    button[data-toggle="collapse"] .fa {
      -webkit-transition: all 0.35s ease-in-out;
      transition: all 0.35s ease-in-out;

      &.fa-chevron-right {
        /*transition-delay: 5s;*/
        //opacity: 0;
      }

      &.fa-chevron-left {
        //opacity: 1;
      }
    }

    &.show {
      width: 250px;

      button[data-toggle="collapse"] .fa {
        &.fa-chevron-right {
          //opacity: 1;
          transform: rotate(180deg);
        }

        &.fa-chevron-left {
          //opacity: 0;
          transform: rotate(180deg);
        }
      }
    }

    ul.nav.h100.flex.flex-column {
      & > li {
        flex: 0 0 auto;

        &.diff {
          flex: 1 1 auto;
        }
      }
    }
  }

  &.left {
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    width: 250px;

    button[data-toggle="collapse"] .fa {
      -webkit-transition: all 0.35s ease-in;
      transition: all 0.35s ease-in;
      position: relative;

      &.fa-chevron-left {
        -webkit-transition: all 0.35s ease-in;
        transition: all 0.35s ease-in;
        transform: rotate(180deg);
        top: -1px;
        left: 0;
      }

      &.fa-chevron-right {
        //opacity: 1;
      }
    }

    &:not(.show) {
      display: block;
      transform: none;
      margin-left: -236px;
      width: 250px;
      height: auto;
    }

    &.show button[data-toggle="collapse"] .fa {
      -webkit-transition: all 0.35s ease-in;
      transition: all 0.35s ease-in;
      &.fa-chevron-left {
        //opacity: 1;
        transform: rotate(0deg);
        top: 1px;
        left: -1px;
      }

      &.fa-chevron-right {
        //opacity: 0;
        transform: rotate(180deg);
      }
    }


  }
}

.collapsing.vertical {
  ul li button {
    //opacity: 0;
  }

  button[data-toggle="collapse"] .fa {
    opacity: 0;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }


  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 250px;
  height: auto !important;
  overflow: visible;

  &.right {
    padding-left: 14px;
    padding-right: 12px;
  }

  .text {
    display: none;
  }
}

.vertical.right {
  ul {
    overflow: hidden;
    max-width: 100%;
    li {
      overflow: hidden;
      max-width: 100%;
      button {
        align-items: flex-start!important;
        text-align: left!important;
        padding-left: 4px!important;
        padding-right: 4px!important;
        transition: all 0.35s ease-in-out;
        white-space: nowrap!important;
        max-width: 100%!important;
        width: 100%!important;
        overflow: hidden!important;
        text-overflow: clip!important;
        display: flex;
        b {
          width: 26px;
          min-width: 26px;
          display: flex;
          text-align: center!important;
          justify-items: center!important;
          justify-content: center!important;
          align-items: center!important;
          padding: 0;
          height: 19px;
          i {
            padding: 0!important;
            margin: 0!important;
            line-height: 0!important;
          }
        }
        span {
          -webkit-transition: all 0.35s ease-in-out;
          transition: all 0.35s ease-in-out;
        }
      }
    }
  }
}

.vertical.right,
.vertical.left {
  width: 250px;
}

.vertical.right + div .collapsible-padding {
  transition-delay: .175s;
  transition: all 0.175s ease;
}

.multicolumn {
  .collapse.vertical.left:not(.show) {
    margin-left: -326px;
    width: 350px;
  }

  .collapsing.vertical.left {
    width: 350px;
  }

  .vertical.left {
    width: 250px;
  }
}
