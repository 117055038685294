#main-body .flex-form .form-group{
  padding: .9rem .25rem 0 .25rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 0!important;
  flex-grow: 1;
  flex-shrink: 1;
}

@media (min-width: 576px) {
  #main-body .flex-form {
    display: grid;
    justify-content: start;
    flex-flow: inherit;
    grid-template-columns: repeat(auto-fit, minmax(55px, 1fr));
    grid-gap: 0;
  }
}

@media (max-width: 960px) {
  flex: 1 1 auto;
}

#main-body .flex-form .form-group > input:empty + label,
#main-body .flex-form .form-group > select + span + label {
  height: .9rem;
  width: 100%;
  position: absolute;
  top: .1rem;
  left: .25rem;
  padding-left: .25rem;
  font-size: .75rem;
  line-height: .9rem;
  z-index: 100000;
  -webkit-clip-path: inherit;
  clip-path: inherit;
  clip: inherit;
  justify-content: flex-start;
  font-weight: 300;
}

#main-body .flex-form .form-group > input:placeholder-shown + label, #main-body .flex-form .form-group > select.empty + span + label {
  top: -15px;
  opacity: 0;
}