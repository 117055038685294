//
// Widget 15
//




.kt-widget-15 {
	.kt-widget-15__body {
		display: flex;
		align-items: stretch;		
		flex-wrap: wrap;

		@include kt-media-below(lg) {
			flex-direction: column;
		}

		.kt-widget-15__author {
			display: flex;
			align-items: stretch;
			padding-right: 1rem;
			flex: 1 1 auto;

			.kt-widget-15__photo {
				border-radius: 50%;
				overflow:hidden;
				width: 5rem;
				height: 5rem;
				flex: 0 0 5rem;
				margin-right: 1.25rem;

				@include kt-mobile-sm {
					margin-bottom: 0.5rem;
				}

				img {
					width:100%;
				}
			}

			.kt-widget-15__detail {
				display: flex;
				flex-direction: column;		
				flex-wrap: wrap;
				justify-content: center;

				.kt-widget-15__name {
					margin-bottom: 0.25rem;
					font-size: 1.3rem;
					font-weight: 500;
					color: kt-base-color(label, 4);
					@include kt-transition();

					&:hover{
						@include kt-transition();
						color: kt-state-color(brand, base);
					}
				}

				.kt-widget-15__desc {
					font-weight: 400;
					color: kt-base-color(label, 2);
				}
			}

			@include kt-media-below(lg) {
				margin-bottom: 1rem;
			}
		}

		.kt-widget-15__wrapper  {
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
			flex: 1 1 auto;

			@include kt-media-range(sm, 1500px) {
				flex-direction: column;
			}

			.kt-widget-15__info {
				display: flex;
				align-items: center;				
				flex: 1;
				padding: 0 2rem;
				font-weight: 500;
				color: kt-base-color(label, 2);
				flex-wrap: wrap;
				border-left: 1px solid kt-base-color(grey, 2);

				@include kt-media-below(lg) {
					padding: 0;
					margin-bottom: 0.5rem;
					border-left: 0;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.kt-widget-15__info--item {
					color: kt-base-color(label, 4);
					@include kt-transition();

					&:hover{
						@include kt-transition();
						color: kt-brand-color();
					}
				}

				.btn {
					margin-right: 1rem;
				}
			}
		}
	}

	.kt-widget-15__foot{
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		margin-top: 2.5rem;

		.kt-widget-15__foot-info,
		.kt-widget-15__foot-toolbar{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			flex: 1;
		}

		.kt-widget-15__foot-info{
			.kt-widget-15__foot-label{
				margin: 0.25rem 0.5rem 0.25rem 0;			
			}

			.kt-widget-15__foot-desc{
				font-weight: 500;
				color: kt-base-color(label, 2);
			}			
		}

		.kt-widget-15__foot-toolbar {
			display: flex;
			justify-content: flex-end;

			a{
				margin: 0.25rem 0 0.25rem 0.5rem;	
			}
		}		
	}	
}