//
// Widget 3
//

// General Mode


.kt-widget-3 {
	overflow: hidden;
	width: 100%;
	height: 100%;

	@include kt-rounded {
        border-radius: $kt-border-radius;
    }

	.kt-widget-3__content {
		padding: 3rem;	
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;

		.kt-widget-3__content-info {
			padding-bottom: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.kt-widget-3__content-section {
				color: #FFFFFF;

				.kt-widget-3__content-title {
					font-size: 1.5rem;
					font-weight: 500; 
				}

				.kt-widget-3__content-desc {
					color: #9686FA;
					font-weight: 500;  
					font-size: 0.9rem;
				}

				.kt-widget-3__content-bedge {
					font-size: 1.8rem;				
					position: relative;
					bottom: 0.8rem;
					right: 0.3rem; 
				}

				.kt-widget-3__content-number {
					font-size: 3.2rem;
					font-weight: 600;

					> span {
						font-size: 1.9rem;
						font-weight: 400;
					}
				}
			}
		}

		.kt-widget-3__content-stats {
			.kt-widget-3__content-progress {
				padding-bottom: 0.7rem;

				.progress {
					height: 0.4rem;

					.progress-bar {						
						border-radius: 3px;
					}
				}
			}

			.kt-widget-3__content-action {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.kt-widget-3__content-text {
					font-weight: 510;
					font-size: 0.95rem;
				}

				.kt-widget-3__content-value {
					font-weight: 510;
					font-size: 0.95rem;
				}
			}
		}
	}

	// States
	@each $name, $color in $kt-state-colors {
		&.kt-widget-3--#{$name} {
		 	background: kt-get($kt-state-colors, $name, base);

	        .kt-widget-3__content-title {
	        	color: kt-get($kt-state-colors, $name, inverse);
	        }

	        .kt-widget-3__content-badge {
	        	color: kt-get($kt-state-colors, $name, inverse);
	        }

	        .kt-widget-3__content-number {
	        	color: kt-get($kt-state-colors, $name, inverse);
	        }

			.kt-widget-3__content-desc {
				color: rgba(kt-get($kt-state-colors, $name, inverse), 0.5) !important;
			}

			.kt-widget-3__content-stats {
				.kt-widget-3__content-progress {
					.progress {
						background: rgba(kt-get($kt-state-colors, $name, inverse), 0.3);
					}
				}
			}

			.kt-widget-3__content-text {
				color: rgba(kt-get($kt-state-colors, $name, inverse), 0.5);
			}

			.kt-widget-3__content-value {
				color: rgba(kt-get($kt-state-colors, $name, inverse), 0.5);
			}
		}
	}
}

// Responsive mode(ref $kt-media-breakpoints in framework\_config.scss)
// Below 1200px
@include kt-media-below(xl) {
	.kt-widget-3 {
		.kt-widget-3__content {
			padding: 1.5rem;	

			.kt-widget-3__content-info {
				.kt-widget-3__content-section {
					.kt-widget-3__content-title {
						font-size: 1.2rem;
					}

					.kt-widget-3__content-number {
						font-size: 2rem;

						> span {
							font-size: 1.2rem;
						}
					}
				}
			}
		}
	}
}


// Mobile Mode
@include kt-mobile {
	.kt-widget-3 {
		.kt-widget-3__content {
			padding: 1rem;
		}	
	}
}