//
// Page
//




// Page body
body {
	background-color: $kt-page-bg-color;
}

.kt-base-bg-color {
	background-color: $kt-page-bg-color;
}

// Desktop mode
@include kt-desktop {
	// Wrapper	
	.kt-wrapper {
		transition: kt-get($kt-aside-config, base, minimize, transition);
		
		// Fixed Aside
		.kt-aside--fixed & {
			padding-left: kt-get($kt-aside-config, base, default, width);
		}

		// Minimize Aside
		.kt-aside--fixed.kt-aside--minimize & {
			padding-left: kt-get($kt-aside-config, base, minimize, width);
			transition: kt-get($kt-aside-config, base, minimize, transition);
		}
	}

	// Page
	.kt-page {
		// Fixed Header
		.kt-header--fixed & {
			padding-top: kt-get($kt-header-config, base, desktop, default, height);
		}

		// Fixed Content Head
		.kt-header--fixed.kt-subheader--fixed.kt-subheader--enabled & {
			padding-top: kt-get($kt-header-config, base, desktop, default, height) + kt-get($kt-subheader-config, fixed, height);
		}
	}

	// Subheader
	.kt-subheader {
		margin: 0;

		// Fixed subheader mode
		.kt-subheader--fixed & {
			position: fixed;			
			height: kt-get($kt-subheader-config, fixed, height);
			top: kt-get($kt-header-config, base, desktop, default, height);
			left: 0;
			right: 0;
			transition: all 0.3s ease;
			z-index: kt-get($kt-header-config, base, desktop, fixed, zindex) - 1;
			box-shadow: 0px 10px 30px 0px rgba(82,63,105, 0.08);
			background-color: #fff;
			padding-top: 0;
			padding-bottom: 0;
		}

		.kt-aside--enabled.kt-subheader--fixed & {
			left: kt-get($kt-aside-config, base, default, width);
		}

		// Solid style 
		.kt-subheader--solid & {
			background-color: $kt-page-bg-color;
			border-bottom: 1px solid darken($kt-page-bg-color, 3%);
		}	
	
		// Fixed header, minimized header and fixed subheader
		.kt-header--fixed.kt-header--minimize.kt-subheader--fixed & {
			top: kt-get($kt-header-config, base, desktop, fixed, minimizeHeight);
			transition: top 0.3s ease;
		}
			
		.kt-subheader--fixed.kt-aside--minimize-hover &,
		.kt-subheader--fixed.kt-aside--minimize & {
			left: kt-get($kt-aside-config, base, minimize, width);
			transition: kt-get($kt-aside-config, base, minimize, transition);
		}
	
		.kt-subheader--fixed.kt-aside-secondary--enabled & {
			transition: all 0.3s ease;
			right: kt-get($kt-aside-secondary-config, self, width, default);
		}
	
		.kt-subheader--fixed.kt-aside-secondary--expanded.kt-aside-secondary--enabled & {
			transition: all 0.3s ease;
			right: kt-get($kt-aside-secondary-config, self, width, expanded);
		}

		// Aside secondary integration
		.kt-aside-secondary--enabled:not(.kt-subheader--fixed) & {
			margin-right: kt-get($kt-aside-secondary-config, self, width, default);
			transition: all 0.3s ease;
		}

		.kt-aside-secondary--expanded.kt-aside-secondary--enabled:not(.kt-subheader--fixed) & {
			transition: all 0.3s ease;
			margin-right: kt-get($kt-aside-secondary-config, self, width, expanded);
		}
	}
}

// Tablet and mobile mode
@include kt-tablet-and-mobile {
	// Page
	.kt-page {
		// Fixed Header
		.kt-header-mobile--fixed & {
			padding-top: kt-get($kt-header-config, base, mobile, self, default, height);
		}
	}
}

