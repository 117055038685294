//
// Media
//

 

.kt-media {         
    display: inline-flex;

    background-repeat: no-repeat; 
    background-position: center center; 
    background-size: cover;

    @include kt-media-size(50px, 1.3rem);

        
    @include kt-rounded {
        border-radius: $kt-border-radius;
    }  

    img {                 
        display: flex;
        @include kt-rounded {
            border-radius: $kt-border-radius;
        }      
    }
        
    span {
        display: flex;       
        align-items: center;
        justify-content: center;         
        font-weight: 600;       
        background-color: kt-base-color(grey, 2);
        color: kt-base-color(label, 2);
            
        @include kt-rounded {
            border-radius: $kt-border-radius;
        }
    }      
    
    @each $name, $color in $kt-state-colors {
        &.kt-media--#{$name} { 
            span {                       
                background: rgba(kt-get($color, base), 0.1); 
                color: kt-get($color, base);
            }                         
        }
    }               
    
    // sizing
    &.kt-media--sm {
        @include kt-media-size(30px, 0.9rem);         
    }

    &.kt-media--md {
        @include kt-media-size(47px, 1rem);         
    }
    
    &.kt-media--lg {
        @include kt-media-size(65px, 1.3rem);       
    }
    
    &.kt-media--xl {
        @include kt-media-size(80px, 1.7rem);       
    }
    
    // circle style
    &.kt-media--circle {
        @include kt-rounded {
            border-radius: 50%;
        }  

        img {
            @include kt-rounded {
                border-radius: 50%;
            }  
        } 
            
        span {
            @include kt-rounded {
                border-radius: 50%;
            }              
        } 
    } 
}
 
.kt-media-group {
    display: flex;
    align-items: center;

    .kt-media {
        position: relative;
        z-index: 0;
        margin-left: -10px;
        border: 2px solid #fff;
        transition: all 0.3s ease;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            transition: all 0.3s ease;
            z-index: 1;
        }
    }
}

 