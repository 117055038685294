@font-face {
  font-family: 'DXIcons';
  src: local("DevExtreme Generic Icons"), local("devextreme_generic_icons"), url('/js/keen/assets/vendors/custom/devextreme/icons/dxicons.ttf') format('woff2'), url('/js/keen/assets/vendors/custom/devextreme/icons/dxicons.woff') format('woff'), url('/js/keen/assets/vendors/custom/devextreme/icons/dxicons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
//
// dxdatagrid 
//
#main-body {
  .floatThead-wrapper {
    .kv-thead-float {
      background: #fff;
    }
  }
  // Base
  @mixin kt-datagrid-base() {
    // Pagination colors
    $page-size: 2.25rem;
    $table-space: kt-get($kt-portlet, space, desktop);
    $table-space-mobile: kt-get($kt-portlet, space, mobile);

    .grid-view {
      padding: 0 !important;
      // Table
      table {
        border-collapse: collapse;
        width: 100%;

        thead {
          tr {
            th, td {
              font-weight: 500;

              a[data-sort] {
                display: flex;
                &.asc {
                  &:after {
                    margin-left: 4px;
                    display: inline-block;
                    font-family: DXIcons;
                    content: "\f052";
                  }
                }

                &.desc {
                  &:after {
                    margin-left: 4px;
                    display: inline-block;
                    font-family: DXIcons;
                    content: "\f051";
                  }
                }
              }
            }

            th, td {
              & > input,
              & > input[type="text"],
              & > input[type="search"],
              & > select {
                box-sizing: border-box;
                display: block;
                height: 28px;
                background-color: #fff;
                border: 1px solid #aaa;
                border-radius: 4px;
                font-size: 12.8px;
                padding: 0 8px;
                vertical-align: middle;
              }
            }
          }
        }

        > head,
        > tbody,
        > tfoot {
          > tr {
            > td {
              vertical-align: middle;
              padding: 10px;
              font-size: 1rem;

              &:first-child {
                //padding-left: $table-space;
                &.kv-row-select {
                  padding-left: 10px;
                }
              }

              &:last-child {
                //padding-right: $table-space;
              }
            }
          }
        }

        // General
      }

      ul.pagination {
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        li {
          padding: 0;
          margin-right: 5px;

          span,
          a {
            border-radius: 3px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: $page-size;
            min-width: $page-size;
            vertical-align: middle;
            padding: 0.5rem;
            text-align: center;
            position: relative;

            font-size: 1rem;
            line-height: 1rem;
            font-weight: 500;

            > i {
              font-size: 1rem;
              text-align: center;
              display: inline-block;
            }
          }
        }
      }

    }

  }

  @mixin kt-datagrid-skin() {
    // Pagination colors
    $base_color: #f4f3f8;
    $page-default: kt-base-color(shape, 1);
    $page-default-font: kt-base-color(shape, 3);
    $page-hover: kt-state-color(brand, base);
    $page-hover-font: kt-state-color(brand, inverse);
    $page-active: kt-state-color(brand, base);
    $page-active-font: kt-state-color(brand, inverse);

    .grid-view {
      // Table
      table {
        // General
        th, td {
          color: kt-base-color(label, 3);
        }

        // Row selected
        .selected {
          th, td {
            background-color: kt-base-color(grey, 1);
            color: kt-base-color(label, 3);
          }
        }

        // Group
        .group {
          th, td {
            background-color: kt-base-color(grey, 1);
          }
        }
      }

      // Pagination
      ul.pagination {
        li {
          & > span,
          & > a {
            color: $page-default-font;
            border: 0;
            outline: none !important;
          }

          & > a {
            @include kt-transition();

            &:hover {
              background: $page-hover;
              color: $page-hover-font;
            }
          }

          &.active {
            & > a {
              background: $page-active;
              color: $page-active-font;
            }
          }

          &.first,
          &.prev,
          &.next,
          &.last {
            & > span,
            & > a {
              background: $page-default;
            }

            & > a:hover {
              background: $page-hover;
              color: $page-hover-font;
            }
          }

          &.disabled,
          &.disabled:hover {
            & > span,
            & > a {
              color: $page-default-font;
              background: $page-default;
              opacity: 0.3;
            }
          }
        }
      }
    }
  }

  // Build
  // Base
  @include kt-datagrid-base();

  // Skin
  @include kt-datagrid-skin();
}