$grey-300: #ebedf2;
.inline-block {
  display: inline-block
}

.block {
  display: block
}

.font-antialiased {
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  font-smoothing: antialiased;
}

.flex-middle,
.flex-middle-force {
  display: flex;
  align-items: center;
}

.flex-vertical-end {
  align-items: flex-end;
}

.m-a {
  margin: auto;
}

.mh50 {
  max-height: 50vh;
  overflow: auto;
}

.mh35 {
  max-height: 35vh;
  overflow: auto;
}

.flex {
  display: flex;

  &.flex-nowrap, .flex-nowrap {
    flex-flow: nowrap;
  }

  &.flex-center {
    justify-content: center;
  }
}


.flex-flow-row {
  flex-flow: row wrap;
}

.flex-flow-column {
  flex-flow: column wrap;
}

.flex-column {
  flex-flow: column nowrap;
}

.flex-stretch {
  align-self: stretch;
  display: flex;
}


body .flex-stretch-inside * {
  align-self: stretch;
  display: flex;
}

.flex-self-stretch {
  align-self: stretch;
}

.flex-around {
  justify-content: space-around;
}

.flex-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-basis-0 {
  flex-basis: 0;
}

.flex-basis-1 {
  flex-basis: 1;
}

.flex-1 {
  flex: 1 1 auto;
}

.flex-0 {
  flex: 0 0 auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-self-middle {
  align-self: middle;
}

.t-u {
  text-transform: uppercase;
}

.t-c {
  text-transform: capitalize;
}

.t-strikeout {
  text-decoration: line-through;
}

img {
  max-width: 100%;
}

.bg-cover {
  background: no-repeat center center / cover;
}

.bg-contain {
  background: no-repeat center center / contain;
}

.b-b-1-grey {
  border-bottom: 1px solid $grey-300;
}

.b-r-1-grey {
  border-right: 1px solid $grey-300;
}

.b-l-1-grey {
  border-left: 1px solid $grey-300;
}

.b-1-grey {
  border: 1px solid $grey-300;
}

.max-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mw150 {
  max-width: 150px;
}

.mw250 {
  max-width: 250px;
}

a {
  &.u, &.u:focus, &.u:active {
    text-decoration: underline;

    .n-u {
      text-decoration: none;
    }
  }

  &.n-u {
    &:hover {
      text-decoration: none;
    }
  }
}

.nowrap-td-inside {
  td {
    white-space: nowrap;
  }
}

.b-r-5 {
  border-radius: 5px;
}

.b-r-8 {
  border-radius: 8px;
}

.break-words {
  word-break: break-all;
}

@media (max-width: 767px) {
  .xs-center {
    text-align: center;
  }

  .xs-left {
    text-align: left;
  }

  .xs-right {
    text-align: right;
  }

  .xs-float-none {
    float: none !important;
  }

  .xs-float-right {
    float: right !important;
  }

  .xs-m-0 {
    margin: 0 !important;
  }
  .xs-m-10 {
    margin: 10px !important;
  }
  .xs-p-0 {
    padding: 0 !important;
  }
  .xs-p-10 {
    padding: 10px !important;
  }
  .xs-p-5 {
    padding: 5px !important;
  }
  .xs-m-b-0 {
    margin-bottom: 0;
  }

  .xs-small {
    font-size: .75em;
  }

  body .xs-inline-block {
    display: inline-block;

    &.visible-xs {
      display: inline-block !important;
    }
  }
  body .xs-inline-block-force {
    display: inline-block !important;
  }
  .xs-block {
    display: block;
  }

  .xs-flex {
    display: flex;
  }

  .xs-p-r {
    position: relative !important;
  }

  .xs-w100 {
    width: 100% !important;
  }

  .xs-flex-center {
    align-items: center;
  }

  .xs-o-auto {
    overflow: auto;
  }
}

i.material-icons {
  font-size: 1.5rem;
  line-height: 1.4;
}

span.glyphicon {
  font-size: 1.1rem;
}


.tooltip-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.tooltip-table-cell.colspan-2 {
  grid-column: 1 / -1;
}

[data-toggle="popover-x"] + .popover {
  display: none;
}

#main-body .portlet-footer #summary {
  padding: 2px 10px;
  z-index: 3;
  position: relative;
  border-top: 3px #d8d8d8 solid;
}

@media (min-height: 400px) {
  #main-body .float-header {

    position: relative;
    z-index: 1;
    -webkit-overflow-scrolling: touch;

    thead tr {
      background-color: white;
    }

    thead th,
    thead td {
      white-space: nowrap;
    }

    thead th {
      padding: 1px 4px !important;

      &.kv-all-select {
        padding: 0px 1px !important;
      }
    }

    thead td {
      padding: 1px !important;
    }

    thead th {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1000003;
    }

    thead tr + tr th,
    thead tr + tr td {
      position: sticky;
      top: 22px;
      background-color: white;
      z-index: 1000003;
    }

    tbody,
    tbody tr,
    {
      position: relative;
      z-index: auto;
    }

    tbody .dropdown-menu.show {
      z-index: 1000001;
    }

    thead .btn.btn-icon.btn-sm {
      height: 1.9rem;
    }

    .kv-grid-container {
      width: fit-content;
      min-width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      &::before {
        display: block;
        content: " ";
        height: 54px;
        background-color: rgb(235, 237, 242);
        z-index: 1;
        position: sticky;
        top: 0;
        left: 0;
        width: 100% !important;
        margin-top: -54px;
        z-index: 1000002;
      }

      .kv-expand-detail-row {
        position: relative;
        z-index: 2;

        thead tr {
          background-color: white;
        }

        thead th,
        thead td {
          white-space: nowrap;
        }

        thead th {
          padding: 1px 4px !important;
        }

        thead td {
          padding: 1px !important;
        }

        thead th {
          position: inherit;
          background-color: white;
          z-index: 1000003;
        }

        thead tr + tr th,
        thead tr + tr td {
          position: inherit;
          background-color: white;
        }


        .kv-grid-container {
          &::before {
            height: auto;
            display: none;
          }
        }


      }
    }

    .popover-body {
      padding: 0;

      .kv-grid-container {
        &::before {
          height: 22px;
          margin-top: -22px;
        }

        > table {
          margin-bottom: 0;
        }
      }
    }

    .expandable {
      position: relative;
      z-index: 1;
    }

    .table-responsive {
      overflow: inherit;
      min-width: 100%;
      //width: auto;
    }
  }
}

#main-body .grid-as-list-item {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}

.items [data-key] .even-bg {
  padding: 1rem;
}

.items [data-key]:nth-of-type(even) .even-bg {
  background-color: #f2f2f2;
}

.hide {
  display: none;
}

.dx-actions-button .btn {
  flex: 0 0 auto;
  width: 1.655rem;
}
#main-body .modal {
  width: 100%;
  > .modal-dialog {
    width: 100%;
  }
}

.onoffswitch, .onoffswitch-2 {
  position: relative;
  width: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox, .onoffswitch-checkbox-2 {
  display: none;
}

.onoffswitch-label, .onoffswitch-label-2 {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 11px;
  padding: 0;
  line-height: 11px;
  border: 0px solid #FFFFFF;
  border-radius: 21px;
  background-color: #F0F0F0;
}

.onoffswitch-label:before, .onoffswitch-label-2:before {
  content: "";
  display: block;
  width: 21px;
  margin: -5px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border-radius: 21px;
  box-shadow: 0 6px 12px 0px #757575;
}

.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox-2:checked + .onoffswitch-label-2 {
  background-color: #A6E3A4;
}

.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before,
.onoffswitch-checkbox-2:checked + .onoffswitch-label-2, .onoffswitch-checkbox-2:checked + .onoffswitch-label-2:before {
  border-color: #A6E3A4;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner,
.onoffswitch-checkbox-2:checked + .onoffswitch-label-2 {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before,
.onoffswitch-checkbox-2:checked + .onoffswitch-label-2:before {
  right: 0px;
  background-color: #1EC73A;
  box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
}
