//
// Widget 13
//




.kt-widget-13 {
	.kt-widget-13__body {
		margin-bottom: 1rem;

		.kt-widget-13__title {
			font-size: 1.3rem;
			font-weight: 500;
			color: kt-base-color(label, 4);
			margin-bottom: 1.25rem;
			transition: all 0.3s ease;
			display: block;

			&:hover{
				color: kt-state-color(brand, base);
			}
		}

		.kt-widget-13__desc {
			font-weight: 400;
			font-size: 1rem;
			color: kt-base-color(label, 2);

			&.kt-widget-13__desc--xl {
				font-weight: 600;
				font-size: 1.5rem;
				color: kt-base-color(label, 4);
			}
		}
	}

	.kt-widget-13__foot {
		display:flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 0.5rem 0;
		margin-top: 1rem;

		.kt-widget-13__label {
			display: flex;
			align-items: center;
			padding-right: 0.25rem;

			i{
				margin-right: 0.5rem;
				font-size: 1.1rem;
			}
		}

		.kt-widget-13__progress {
			width: 100%;

			.kt-widget-13__progress-info {
				width: 100%;
				margin-bottom: 0.5rem;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.kt-widget-13__progress-status {
					color: kt-base-color(label, 2);
					font-weight: 400;
				}

				.kt-widget-13__progress-value {
					color: kt-base-color(label, 3);
					font-weight: 600;
				}
			}

			.progress {
				height: 5px;
			}
		}
	}	
}