.base-msc-card {
  background: #fff;
  margin-top: 0px;
  border-radius: 5px;
  min-height: 65px;
  position: relative;
  border-left: 5px solid #e0dddd;
  transition: height .2s;
  border: 1px solid #e0dddd;
  border-left-width: 5px;
  transition: .6s all ease-in-out;
  cursor: pointer;
  display: block;
  width: 100%;
  max-width: 284px;

  > .kt-portlet {
    padding-bottom: 0 !important;
    .details {
      list-style: none;
      display: flex;
      flex-direction: column;

      > li {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        /*border-bottom: 1px solid rgba(222, 226, 230, .6) !important;*/

        > {
          span {
            font-weight: 300;
            display: inline-block;
            min-width: 90px;
            padding-right: 4px;
            flex: 0;
            text-align: left;
            opacity: .9;
          }

          div {
            flex: 1;
          }
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 0 10px rgba(29, 201, 183, 0.5);
    border-color: rgba(29, 201, 183, 0.5);
  }

  &[active="true"] {
    border-color: rgba(29, 201, 183, 0.5);
  }

  .kt-media--brand {
    border: 5px solid white;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  }
}